<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <dx-data-grid
        ref="refOrderDetailGrid"
        :data-source="sapUpdateFailList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxFilterRow
          :visible="false"
          apply-filter="auto"
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn
          :allow-editing="false"
          caption="NO"
          data-field="taskId"
          sort-order="asc"
          :min-width="60"
        />
        <DxColumn
          :allow-editing="false"
          caption="항목"
          data-field="opType"
          :min-width="100"
        >
          <DxLookup
            :data-source="opTypeLookup"
            display-expr="코드명"
            value-expr="코드"
          />
        </DxColumn>
        <DxColumn
          :allow-editing="false"
          caption="품번"
          data-field="품번"
          :min-width="100"
        />
        <DxColumn
          :allow-editing="false"
          caption="수량"
          data-field="수량"
          :min-width="60"
        />
        <DxColumn
          :allow-editing="false"
          caption="실패사유"
          data-field="message"
          width="400"
        />
        <DxColumn
          width="70"
          :buttons="editButtons"
          type="buttons"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            show-in-column="opType"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import DxDataGrid, { DxColumnFixing, DxLookup, DxScrolling, DxColumn, DxPaging, DxSorting, DxSearchPanel, DxFilterRow, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import ConstDef from '../../../BnhMesVueWin/src/share/ConstDef'
// import AppLib from '../share/AppLib'
import HttpService from '../share/service/HttpService'
// import AesCrypto from '../share/service/AesCrypto'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxFilterRow,
    DxSummary,
    DxTotalItem,
    DxDataGrid,
    DxLookup,
    DxColumnFixing
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      sapUpdateFailList: null,
      gridHeight: null,
      editButtons: [
        {
          hint: '재전송',
          icon: 'refresh',
          visible: 'true',
          onClick: this.onRefreshSapUpdate
        },
        {
          hint: '강제종료',
          icon: 'close',
          visible: 'true',
          onClick: this.onEndSapUpdate
        }],
      opTypeLookup: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.opTypeLookup = ConstDef.OP_TYPE_KOOKUP
    this.refreshSapResult()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 80)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize (event) {
      this.gridHeight = (this.$Q('html').height() - 80)
    },
    onEndSapUpdate (e) {
      console.log(e.row.data)
      this.$snotify.confirm('강제 종료 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '종료',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              const param = {
                sapUpdated: true
              }
              const key = e.row.data.keyName.toLowerCase()
              param[key] = e.row.data.taskId
              this.$_sp.runUpdateSqlProc(e.row.data.target, [key], null, [param])
                .then((dta) => {
                  this.refreshSapResult()
                })
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onRefreshSapUpdate (e) {
      console.log(e.row.data.opType)
      const parma = {
        operationType: e.row.data.opType,
        taskId: e.row.data.taskId
      }
      HttpService.reqPostBody(parma, '/refreshSapResult')
      this.$snotify.info('재전송 요청 하였습니다.')
    },
    refreshSapResult () {
      this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllSapUpdatedByFail', {})
        .then((data) => {
          this.isLoading = false
          this.sapUpdateFailList = this.$_sp.MakeModel(data)
          console.log(this.sapUpdateFailList)
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
